<template>
  <div class="app-main-content" style="padding-bottom: 80px;">
     <div class="upgrade-navs">
		 <div class="upgrade-nav-left">共有<span class="upgrade-nav-val">{{isLoad ? dataList.length : 0}}</span>款软件可升级</div>
		 <div class="upgrade-nav-ret gestures">
			 <img src="../../assets/home/huanyihuan.png" class="upgrade-nav-ret-img"/>
			 <div class="upgrade-nav-ret-text">刷新</div>
		 </div>
	 </div>
	 <div class="load-item" v-if="!isLoad">加载中,请稍后....</div>
	 <div v-if="isLoad&&dataList.length > 0">
		 <div class="upgrade-list" v-for="(value,index) in dataList" :key="index">
		 		 <img src="../../assets/img/no-select.png" class="upgrade-sel-img gestures" v-if="!value.sel" @click="changeSel(index,true)"/>
		 		 <img src="../../assets/img/yes-select.png" class="upgrade-sel-img gestures" v-if="value.sel" @click="changeSel(index,false)"/>
		 		 <div class="upgrade-list-img" style="margin-left: 10px;">
		 			 <img :src="value.cover" class="upgrade-list-img"/>
		 		 </div>
		 		 <div class="upgrade-list-info">
		 			 <div class="upgrade-list-tel">{{value.name}}</div>
		 			 <div class="upgrade-list-text">{{value.introduction}}</div>
		 		 </div>
		 		 <div class="upgrade-list-size">{{value.size}}</div>
		 		 <div class="upgrade-list-time">2022-10-11 更新</div>
		 		 <div class="upgrade-list-new">最新版本：{{value.version}}</div>
		 		 <div class="upgrade-list-btns gestures" @click="upgradebtn(value)">
		 			 升级
		 			 <!-- <div class="upgrade-speed"></div> -->
		 		 </div>
		 </div>
	 </div>
	 <div v-if="isLoad&&dataList.length == 0">
		 <div class="is-empty-img">
			 <img src="../../assets/img/upgrade-empty-img.png"/>
		 </div>
		 <div class="is-empty-text">软件都为最新版本，无需升级</div>
	 </div>
	 <div v-if="isReLoad&&recommendList.length > 0">
		 <div class="have-item">试试这些有用的软件吧</div>
		 <recommendList :list="recommendList"></recommendList>
	 </div>
	 
	 <div class="upgrade-boom-item" v-if="isLoad&&dataList.length > 0">
		 <img src="../../assets/img/no-select.png" class="upgrade-boom-img gestures" v-if="!isAllSel" @click="allsel(true)"/>
		 <img src="../../assets/img/yes-select.png" class="upgrade-boom-img gestures" v-if="isAllSel" @click="allsel(false)"/>
		 <div class="upgrade-boom-text gestures">全选</div>
		 <div class="upgrade-boom-btns gestures" @click="allUpgrade">一键升级</div>
	 </div>
  </div>
</template>

<script>
	import recommendList from "@/components/recommendList.vue"
	import {searchFun,softwareDetails,recommendData} from "@/api/index.js"
	import qs from 'qs'
	import config from "@/utils/config.js"
	export default {
		components:{recommendList},
		data(){
			return {
				upgradeList:[
					{sel:false},
					{sel:false},
					{sel:false},
					{sel:false},
					{sel:false},
					{sel:false}
				],
				installList:[],
				dataList:[],
				isLoad:false,
				recommendList:[],
				isReLoad:false,
				newInstallList:[]
			}
		},
		computed:{
			isAllSel(){
				let result = false
				if(this.dataList.length > 0){
					let array = this.dataList.filter(item=>item.sel)
					if(array.length == this.dataList.length){
						result = true
					}
				}
				return result
			},
		},
		created() {
			
			let data = window.win_program_list()
			
			this.installList = JSON.parse(data)
			this.newInstallList = JSON.parse(JSON.stringify(this.installList))
			localStorage.setItem('installData',data)
			// console.log(this.installList)
			this.handleData()
		},
		methods:{
			handleData(){
				// console.log(this.installList)
				let item = this.installList.shift();
				let prm = {
					keyword:item.DisplayName,
					page:1,
					page_size:10
				}
				searchFun(qs.stringify(prm)).then(res=>{
					// console.log(res)
					if(res.list.length > 0){
						if(res.list[0].version != item.Version){
							res.list[0].sel = false
							this.dataList.push(res.list[0])
							this.resultFun()
						}else{
							this.resultFun()
						}
					}else{
						this.resultFun()
					}
					
				})
			},
			resultFun(){
				console.log(this.installList.length)
				if(this.installList.length > 0){
					this.handleData()
				}else{
					this.isLoad = true
					if(this.dataList.length > 0){
						this.getRecommend(this.dataList[0].slug)
					}else{
						this.getRecommend(56606)
					}
					// console.log(this.dataList)
				}
			},
			getRecommend(id){
				
				recommendData(id).then(res=>{
					res.list.forEach(item=>{
						item.installType = 0
						let array = config.getSearch(this.newInstallList, item.name)
						if(array.length > 0){
							if(item.version != array[0].Version){
								item.installType = 1
							}else{
								item.installType = 2
							}
						}
					})
					this.recommendList = res.list
					this.isReLoad = true
				})
			},
			changeSel(index,type){
				this.dataList[index].sel = type
			},
			allsel(type){
				this.dataList.forEach(item=>{
					item.sel = type
				})
			},
			allUpgrade(){
				let selList = this.dataList.filter(item=>item.sel)
				if(selList.length == 0){
					return false
				}
				selList.forEach(item=>{
					config.getDownload(item)
				})
			},
			upgradebtn(data){
				config.getDownload(data)
			},
		}
	}
</script>
<style lang="scss">
	.is-empty-img{
		text-align: center;
		padding-top: 110px;
		img{
			width: 200px;
			height: 160px;
		}
	}
	.is-empty-text{
		text-align: center;
		padding-top: 20px;
		color: #646C88;
		font-size: 14px;
	}
	.load-item{
		text-align: center;
		padding-top: 20px;
		color: #999999;
		font-size: 15px;
	}
	.have-item{
		margin-top: 40px;
		color: #1E2644;
		font-size: 16px;
		font-weight: bold;
	}
	.upgrade-boom-item{
		position: fixed;
		bottom: 0;
		left: 170px;
		right: 0;
		background-color: #ffffff;
		height: 70px;
		border-top: 1px solid #E5E5E5;
		padding: 0px 34px 0px 20px;
		display: flex;
		align-items: center;
		z-index: 99;
		.upgrade-boom-img{
			width: 15px;
			height: 15px;
		}
		.upgrade-boom-text{
			color: #4D5571;
			font-size: 14px;
			margin-left: 9px;
		}
		.upgrade-boom-btns{
			margin-left: auto;
			width: 100px;
			height: 36px;
			text-align: center;
			line-height: 36px;
			border-radius: 18px;
			background-color: #00B386;
			color: #ffffff;
			font-size: 12px;
		}
	}
	.upgrade-list{
		display: flex;
		align-items: center;
		padding: 18px 10px 14px 0px;
		border-bottom: 1px solid #EEF2F6;
		.upgrade-sel-img{
			width: 15px;
			height: 15px;
		}
		.upgrade-list-img{
			width: 48px;
			height: 48px;
		}
		.upgrade-list-info{
			margin-left: 10px;
			width: 25%;
			.upgrade-list-tel{
				color: #1E2644;
				font-size: 13px;
				font-weight: bold;
			}
			.upgrade-list-text{
				color: #646C88;
				font-size: 12px;
				margin-top: 8px;
				overflow: hidden; //超出的文本隐藏
				text-overflow: ellipsis; //溢出用省略号显示
				display: -webkit-box;
				-webkit-line-clamp: 1; // 超出多少行
				-webkit-box-orient: vertical;
			}
		}
		.upgrade-list-size{
			color: #646C88;
			font-size: 12px;
			margin-left: 30px;
		}
		.upgrade-list-time{
			color: #646C88;
			font-size: 12px;
			margin-left: 21px;
		}
		.upgrade-list-new{
			color: #00B386;
			font-size: 12px;
			margin-left: 28px;
		}
		.upgrade-list-btns{
			margin-left: auto;
			width: 90px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #00B386;
			border-radius: 16px;
			color: #00B386;
			font-size: 12px;
			position: relative;
			.upgrade-speed{
				position: absolute;
				border-radius: 16px;
				left: 0;
				top: 0;
				bottom: 0;
				background: rgba(0,179,134,0.2);
				width: 70%;
			}
		}
	}
	.upgrade-navs{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 7px 10px;
		background-color: #F2F6F6;
		.upgrade-nav-left{
			color: #4D5571;
			font-size: 12px;
			.upgrade-nav-val{
				color: #00B386;
				font-weight: bold;
				padding: 0px 4px;
			}
		}
		.upgrade-nav-ret{
			display: flex;
			align-items: center;
			.upgrade-nav-ret-img{
				width: 14px;
				height: 15px;
				margin-right: 4px;
			}
			.upgrade-nav-ret-text{
				color: #00B386;
				font-size: 12px;
			}
		}
	}
</style>
