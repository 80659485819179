<template>
  <div class="app-main-content">
        <div class="necr-tab-list">
			<div class="necr-tab-item gestures" :class="{'necr-tabs':necrTabIndex==index,'neceClick':value.sel}" @click="changeNece(value,index)"
			 v-for="(value,index) in necrTab" :key="index" @mouseover="neceOverGame(index,false)"  @mouseleave="neceOverGame(index,true)">{{value.name}}</div>
		</div>
		<div v-if="necrTabIndex==0">
			<div class="nece-banner">
				<el-carousel :interval="5000" height="240px" ref="carousel" @change="changeBanner">
				    <el-carousel-item v-for="(item,key) in bannerData" :key="key">
				       <img :src="item.application.cover" class="nece-banner-img"/>
				    </el-carousel-item>
				  </el-carousel>
				  <div class="nece-banner-info" @mouseover="mouseoverBanner(true)"  @mouseleave="mouseoverBanner(false)">
					  <div style="display: flex;">
						  <div class="banner-info-tel">{{bannerData[bannerIndex]?.application.name}}
						  	<div class="banner-tel-tag">推荐</div>
						  </div>
					  </div>
					  <div class="banner-info-text">{{bannerData[bannerIndex]?.application.introduction}}</div>
					  <div class="banner-info-boom">
						  <div class="banner-info-btn gestures" @click="install(bannerData[bannerIndex]?.application)">安装</div>
						  <div class="banner-info-num">{{bannerData[bannerIndex]?.application.downloadCount}}</div>
					  </div>
				  </div>
			</div>
			
			<div class="nece-tel-item">热门精选</div>
			<el-row :gutter="22">
			  <el-col :span="6" v-for="(value,index) in neceRow" :key="index">
				  <div class="nece-row-item gestures" @mouseover="mouseoverSift(true,index)"  @mouseleave="mouseoverSift(false,index)" @click="jumpDetails(value)">
					  <div class="nece-row-img">
						  <img :src="value.application.cover" class="nece-row-img"/>
					  </div>
					  <div class="nece-row-ret">
						  <div class="nece-row-tels">{{value.application.name}}</div>
						  <div class="nece-row-texts ellipsis-text" v-if="!value.sel">{{value.application.introduction}}</div>
						  <div class="nece-row-btn" v-if="value.sel&&value.installType == 0" @click.stop="install(value.application)">安装</div>
						  <div class="nece-row-btn" v-if="value.sel&&value.installType == 1" @click.stop="install(value.application)">升级</div>
						  <div class="nece-row-btn" v-if="value.sel&&value.installType == 2" @click.stop="openAr(value.application)" >打开</div>
					  </div>
				  </div>
			  </el-col>
			</el-row>
			<div class="nece-tel-item">安全上网</div>
			<gridList :list="secureList"></gridList>
			<div class="nece-tel-item">办公学习</div>
			<gridList :list="workList"></gridList>
		</div>
		<div v-if="necrTabIndex!=0">
			<softwareList :list="dataList" :isScore="true"></softwareList>
		</div>
  </div>
</template>

<script>
	import softwareList from "@/components/softwareList.vue"
	import {bannerList,moduleList,moduleData} from "@/api/index.js"
	import gridList from "@/components/gridList.vue"
	import config from "@/utils/config.js"
	export default {
		components:{softwareList,gridList},
		data(){
			return {
				necrTab:[],
				necrTabIndex:0,
				neceRow:[],
				rankingVal:4,
				bannerData:[],
				bannerIndex:0,
				secureList:[],
				workList:[],
				dataList:[],
				installList:[]
			}
		},
		created() {
			this.getBanner()
			this.getModuleList()
			let lists = localStorage.getItem('installData')
			if(lists){
				this.installList = JSON.parse(lists)
			}
		},
		methods:{
			jumpDetails(data){
				// console.log(data)
				this.$router.push({ name: 'softwareDetails',query:{id:data.application.slug}});
			},
			install(data){
				config.getDownload(data)
			},
			openAr(data){
				window.win_open_program(data.name)
			},
			getModuleList(){
				let refArray = ['neceRow','secureList','workList']
				moduleList(0).then(res=>{
					res.list.forEach(item=>{
						item.sel = false
						item.list = []
						item.isLoad = false
						
					})
					this.necrTab = res.list
					res.list[0].children.forEach((item,index)=>{
						this.getModuleData(0,item.id,refArray[index])
					})
				})
			},
			getModuleData(type,id,ref){
				moduleData(id).then(res=>{
					res.list.forEach(item=>{
						item.sel = false
						item.scoreVal = item.application.score / 100
						item.application.scoreVal = item.application.score / 100
						item.installType = 0
						let array = config.getSearch(this.installList, item.application.name)
						if(array.length > 0){
							if(item.application.version != array[0].Version){
								item.installType = 1
							}else{
								item.installType = 2
							}
						}
					})
					if(type === 0){
						this[ref] = res.list
					}else{
						this.necrTab[this.necrTabIndex].isLoad = true
						this.necrTab[this.necrTabIndex].list = res.list
						this.dataList = res.list
					}
				})
			},
			getBanner(){
				bannerList(0).then(res=>{
					this.bannerData = res.list
				})
			},
			changeBanner(e){
				this.bannerIndex = e
			},
			neceOverGame(index,type){
				if(!type&&this.necrTabIndex!=index){
					this.necrTab[index].sel = true
				}else{
					this.necrTab[index].sel = false
				}
			},
			changeNece(data,index){
				if(this.necrTabIndex!=index){
					this.necrTab[index].sel = false
					this.necrTabIndex=index
					if(index>0){
						if(data.isLoad){
							this.dataList = data.list
						}else{
							this.getModuleData(1,data.id,'dataList')
						}
					}
				}
			},
			mouseoverBanner(type){
				if(type){
					this.$refs.carousel.handleMouseEnter()
				}else{
					this.$refs.carousel.handleMouseLeave()
				}
			},
			mouseoverSift(type,index){
				this.neceRow[index].sel = type
			},
		}
	}
</script>
<style lang="scss">
	
	.el-rate__icon{
		margin-right: 0px!important;
		font-size: 14px!important;
	}
	.necr-tab-list{
		display: flex;
		background-color: #F2F6F6;
		border-radius: 6px;
		padding: 9px 3px;
		.necr-tab-item{
			width: 100px;
			height: 32px;
			border-radius: 3px;
			text-align: center;
			line-height: 32px;
			color: #000000;
			font-size: 14px;
			margin-left: 6px;
		}
		.necr-tabs{
			background-color: #00B386;
			color: #ffffff;
		}
		.neceClick{
			background-color: #DFEBE6;
		}
	}
	.nece-banner{
		margin-top: 12px;
		height: 240px;
		position: relative;
		.nece-banner-img{
			width: 100%;
			height: 240px;
		}
		.nece-banner-info{
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			width: 250px;
			background-color: rgba(0, 0, 0, 0.5);
			z-index: 9;
			padding-left: 20px;
			padding-top: 40px;
			padding-right: 24px;
			.banner-info-tel{
				color: #FFFFFF;
				font-size: 20px;
				font-weight: bold;
				position: relative;
				.banner-tel-tag{
					width: 33px;
					height: 19px;
					background-color: #FF6A44;
					border-radius: 6px 2px 6px 2px;
					text-align: center;
					line-height: 19px;
					color: #FFFFFF;
					font-size: 12px;
					position: absolute;
					right: -38px;
					top: -6px;
				}
			}
			.banner-info-text{
				margin-top: 12px;
				color: #FFFFFF;
				font-size: 12px;
			}
			.banner-info-boom{
				display: flex;
				align-items: center;
				margin-top: 40px;
				.banner-info-btn{
					width: 76px;
					height: 36px;
					text-align: center;
					line-height: 36px;
					background-color: #00B386;
					border-radius: 18px;
					color: #FFFFFF;
					font-size: 13px;
				}
				.banner-info-num{
					color: #FFFFFF;
					font-size: 12px;
					margin-left: 12px;
				}
			}
		}
	}
	.nece-tel-item{
		padding-top: 24px;
		color: #000000;
		font-size: 20px;
		font-weight: bold;
	}
	.nece-row-item{
		height: 50px;
		display: flex;
		margin-top: 20px;
		.nece-row-img{
			width: 40px;
			height: 40px;
		}
		.nece-row-ret{
			margin-left: 8px;
			flex: 1;
			.nece-row-tels{
				color: #333333;
				font-size: 13px;
			}
			.nece-row-tels:hover{
				color: #00B386;
			}
			.nece-row-texts{
				margin-top: 6px;
				color: #999999;
				font-size: 12px;
			}
			.nece-row-btn{
				width: 56px;
				height: 28px;
				background-color: #00B386;
				border-radius: 14px;
				text-align: center;
				line-height: 28px;
				color: #FFFFFF;
				font-size: 12px;
				margin-top: 4px;
			}
		}
	}
	.three-row-item{
		margin-top: 11px;
		background-color: #F7F7F7;
		border-radius: 6px;
		padding: 16px 15px 0px 15px;
		display: flex;
		height: 104px;
		.three-row-img{
			width: 40px;
			height: 40px;
		}
		.three-row-rets{
			margin-left: 12px;
			flex: 1;
			.three-row-tel{
				color: #000000;
				font-size: 15px;
			}
			.three-row-score{
				display: flex;
				align-items: center;
				margin-top: 8px;
			}
			.three-row-num{
				margin-left: 2px;
				color: #999999;
				font-size: 12px;
			}
			.three-row-texts{
				margin-top: 10px;
				color: #666666;
				font-size: 12px;
				
			}
		}
	}
</style>
