<template>
  <el-row :gutter="37" >
  	<el-col :span="8" v-for="(value,index) in list" :key="index" style="margin-top: 14px;">
  		<div class="recom-lists">
  				<div class="recom-list-img" @click="changeData(value)">
  					<img :src="value.cover" class="recom-list-img"/>
  				</div>
  				<div class="recom-lists-info">
  					<div class="recom-lists-tel gestures" @click="changeData(value)">{{value.name}}</div>
  					<div class="recom-lists-num">{{value.downloadCount}}</div>
  				</div>
  				<div class="recom-list-btn gestures" @click="installs(value)" v-if="value.installType == 0">安装</div>
				<div class="recom-list-btn gestures" @click="installs(value)" v-if="value.installType == 1">升级</div>
				<div class="recom-list-btn gestures" @click="opens(value)" v-if="value.installType == 2">打开</div>
  			</div>
  	</el-col>
  </el-row>
</template>

<script>
	import config from "@/utils/config.js"
	import { mapState } from 'vuex';
	export default {
		props: {
			list:{
				type:Array
			}
		},
		data(){
			return {
				
			}
		},
		methods:{
			installs(data){
				config.getDownload(data)
			},
			opens(data){
				window.win_open_program(data.name)
			},
			changeData(data){
				if(this.$route.name=='softwareDetails'){
					this.$store.commit('savePassId',data.slug)
				}else{
					this.$router.push({ name: 'softwareDetails',query:{id:data.slug}});
				}
			},
			
		}
	}
</script>
<style lang="scss">
	.recom-lists{
		display: flex;
		align-items: center;
		padding: 15px 0px;
		.recom-list-img{
			width: 40px;
			height: 40px;
		}
		.recom-lists-info{
			padding-left: 14px;
			// border-bottom: 1px solid #EEEEEE;
			.recom-lists-tel{
				color: #1E2644;
				font-size: 14px;
				overflow: hidden; //超出的文本隐藏
				
				text-overflow: ellipsis; //溢出用省略号显示
				
				display: -webkit-box;
				
				-webkit-line-clamp: 1; // 超出多少行
				
				-webkit-box-orient: vertical;
			}
			.recom-lists-num{
				margin-top: 6px;
				color: #808080;
				font-size: 12px;
			}
		}
		.recom-list-btn{
			margin-left: auto;
			width: 56px;
			height: 28px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #00B386;
			border-radius: 14px;
			color: #00B386;
			font-size: 12px;
		}
	}
</style>
