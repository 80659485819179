<template>
  <div class="home ">
    <el-row :gutter="12" style="padding: 0px 25px;">
      <el-col :span="16">
		  <div class="home-banner-main">
			  <img :src="bannerData[bannerIndex]?.application.cover" class="home-banner-img"/>
			  <div class="home-banner-list">
				  <div class="banner-list-item gestures" :class="{'banner-sel':index==bannerIndex}" v-for="(value,index) in bannerData" :key="index" @click="changeBanner(index)">
					  <img :src="value.application.cover" class="banner-list-item-img"/>
					  <div class="banner-list-mode" v-if="index!=bannerIndex"></div>
				  </div>
			  </div>
			  <div class="banner-main-boom">
				  <div class="banner-boom-text">{{bannerData[bannerIndex]?.application.name}}</div>
				  <div class="banner-boom-btn gestures" @click="install(bannerData[bannerIndex]?.application)" v-if="bannerData[bannerIndex]?.installType == 0">安装</div>
				  <div class="banner-boom-btn gestures" @click="install(bannerData[bannerIndex]?.application)" v-if="bannerData[bannerIndex]?.installType == 1">升级</div>
				  <div class="banner-boom-btn gestures" @click="opensoft(bannerData[bannerIndex]?.application)" v-if="bannerData[bannerIndex]?.installType == 2">打开</div>
			  </div>
		  </div>
		  
		  <div class="classify-tab-list">
			  <div class="classify-tab-item gestures" 
			  v-for="(value,index) in classifyTab" :key="index" :class="{'classify-sel':index==classifyTabIndex}" @click="changeTab(index,value)">{{value.name}}</div>
		  </div>
		  <el-row>
			  <el-col :span="8" v-for="(value,index) in softwareList" :key="index">
				  <div class="software-list gestures" @mouseover="mouseOverGame('softwareList',index,true)"  @mouseleave="mouseOverGame('softwareList',index,false)">
					  <img :src="value.application.cover" class="software-list-img" @click='goDetails(value.application.slug)'/>
					  <div class="software-list-info">
						  <div class="software-list-tel" @click='goDetails(value.application.slug)' :class="{'software-tel-sel':value.sel}">{{value.application.name}}</div>
						  <div class="software-list-text" v-if="!value.sel">{{value.application.introduction}}</div>
						  <div class="software-list-btn" v-if="value.sel&&value.installType == 0" @click="install(value.application)">安装</div>
						  <div class="software-list-btn" v-if="value.sel&&value.installType == 1" @click="install(value.application)">升级</div>
						  <div class="software-list-btn" v-if="value.sel&&value.installType == 2" @click="opensoft(value.application)">打开</div>
					  </div>
				  </div>
			  </el-col>
		  </el-row>
	  </el-col>
	  <el-col :span="8">
		  <div class="banner-ret-item gestures" @click="jumpBanner('necessary')">
			  <img src="../assets/home/banner-two-1.png" class="banner-ret-img"/>
			  <div class="banner-ret-item-boom">装机必备</div>
		  </div>
		  <div class="banner-ret-item gestures" style="margin-top: 12px;" @click="jumpBanner('work')">
		  		<img src="../assets/home/banner-two-2.png" class="banner-ret-img"/>
		  		<div class="banner-ret-item-boom">办公必备</div>
		  </div>
		  
		  <div class="ranking-card">
			  <div class="ranking-card-top">
				  <div class="ranking-line"></div>
				  <div class="ranking-title">热门软件排行</div>
			  </div>
			  <div class="ranking-card-item gestures" v-for="(value,index) in rankingList" :key="index" @mouseover="mouseRanking(value.application.slug)">
				  <div class="ranking-rank" :class="[index<3?'ranking-rank-'+index:'ranking-rank-mo']">{{index+1}}</div>
				  <div class="ranking-item-one" v-if="value.application.slug==rankingId">
					  <img :src="value.application.cover" class="ranking-shop-img"/>
					  <div class="ranking-rank-info">
						  <div class="ranking-item-name" @click='goDetails(value.application.slug)'>{{value.application.name}}</div>
						  <el-rate
						    v-model="value.application.scoreVal"
						    disabled
						    text-color="#ff9900"
						    score-template="{value}">
						  </el-rate>
					  </div>
					  <div class="ranking-rank-btn gestures" @click="install(value.application)">安装</div>
				  </div>
				  <div class="ranking-item-one" v-if="value.application.slug!=rankingId">
					  <div class="ranking-item-name gestures" @click='goDetails(value.application.slug)'>{{value.application.name}}</div>
					  <div class="ranking-item-num">{{value.application.downloadCount}}</div>
				  </div>
			  </div>
		  </div>
	  </el-col>
    </el-row>
	
	<div class="main-class-name">热门游戏推荐</div>
	<div class="paid-25">
		<gridList :list="hotRecommend"></gridList>
	</div>
	
	
	<div class="main-class-name">实用工具</div>
	<div class="paid-25">
		<gridList :list="utilityList"></gridList>
	</div>
	
	
	<div class="main-class-name">大家都在下载</div>
	<div class="paid-25">
		<gridList :list="downloadList"></gridList>
	</div>
	
	
	<div class="main-class-name">小编推荐榜</div>
	<div style="padding: 18px 25px 0px 25px;">
		<el-carousel :interval="5000" height="250px">
		    <el-carousel-item v-for="(item,key) in editorRecommend" :key="key">
		       <img :src="item.application.cover" class="recommend-banner-img"/>
		    </el-carousel-item>
		  </el-carousel>
	</div>
	
	<el-row :gutter="13" style="margin-top: 20px;padding: 0px 25px;">
	  <el-col :span="8" v-for="(item,key) in rankingData" :key="key">
		  <div class="billboard-top" :class="item.class">
			  <div class="billboard-tel">{{item.text}}</div>
			  <div class="billboard-text gestures">更多</div>
		  </div>
		  <div class="billboard-list">
			  <div class="billboard-item gestures" v-for="(value,index) in item.list" :key="index"  
			  @mouseover="mouseBill(key,index,true)" @mouseleave="mouseBill(key,index,false)">
				  <img :src="require('../assets/home/ranking'+(index+1)+'.png')" class="list-ranking"/>
				  <img :src="value.cover" class="list-shop-img"/>
				  <div class="billboard-item-info">
					  <div class="billboard-shop-tel gestures" :class="{'billboard-sel':value.sel}" @click='goDetails(value.slug)'>{{value.name}}</div>
					  <div class="billboard-rate" v-show="!value.sel">
						  <el-rate
						    v-model="value.scoreVal"
						    disabled
						    text-color="#ff9900"
						    score-template="{value}">
						  </el-rate>
					  </div>
					  <div class="billboard-nums"  v-show="!value.sel">{{value.downloadCount}}</div>
					  <div class="software-list-btn" @click="install(value)"  v-show="value.sel&& value.installType == 0">安装</div>
					  <div class="software-list-btn" @click="install(value)" v-show="value.sel&& value.installType == 1">升级</div>
					  <div class="software-list-btn" @click="opensoft(value)" v-show="value.sel&& value.installType == 2">打开</div>
				  </div>
			  </div>
		  </div>
	  </el-col>
	</el-row>

  </div>
</template>

<script>
import {categoryList,moduleList,moduleData,bannerList,searchFun} from "@/api/index.js"
import gridList from "@/components/gridList.vue"
import qs from 'qs'
import config from "@/utils/config.js"
export default {
	
  name: 'Home',
  components:{gridList},
  data(){
	  return{
		 bannerIndex:0,
		 classifyTab:[],
		 classifyTabIndex:0,
		 softwareList:[],
		 rankingVal:4,
		 rankingList:[],
		 hotRecommend:[],
		 utilityList:[],
		 downloadList:[],
		 editorRecommend:[],
		 bannerData:[],
		 downloadRanking:[],
		 newRanking:[],
		 favorableRanking:[],
		 installList:[],
		 rankingId:'',
		 rankingData:[
			 {text:'一周下载榜',class:'download-bar',list:[],name:'download_count'},
			 {text:'本周新品榜',class:'newp-bar',list:[],name:'id'},
			 {text:'用户好评榜',class:'received-bar',list:[],name:'score'},
		 ]
	  }
  },
  created() {
	
	if(navigator.userAgent.indexOf("123soft") != -1){
		let data = window.win_program_list()
		this.installList = JSON.parse(data)
	}
	
  	this.getModuleList()
	this.getBannerList()
	this.getCharts()
	
  },
  mounted(){
      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);
  },
  methods:{
	mouseBill(key,index,type){
		this.rankingData[key].list[index].sel = type
	},
	mouseRanking(id){
		if(this.rankingId!=id){
			this.rankingId=id
		}
	},
	opensoft(data){
		window.win_open_program(data.name)
	},
	jumpBanner(path){
		this.$router.push({ name: path});
	},
	//  跳转详情
	goDetails(id){
		this.$router.push({ name: 'softwareDetails',query:{id:id}});
	},
	//  下载操作
	install(data){
		config.getDownload(data)
	},
	// 排行
	getCharts(){
		// let array = [{name:'download_count',ref:'downloadRanking'},{name:'id',ref:'newRanking'},{name:'score',ref:'favorableRanking'}]
		
		this.rankingData.forEach((item,index)=>{
			this.getChartsData(item,index)
		})
	},
	getChartsData(data,index){
		let prm = {
			page:1,
			page_size:5,
			order:'desc',
			order_by:data.name
		}
		searchFun(qs.stringify(prm)).then(res=>{
			res.list.forEach(item=>{
				item.scoreVal = item.score / 100
				item.installType = 0
				item.sel = false
				let array = config.getSearch(this.installList,item.name)
				if(array.length > 0){
					if(item.version != array[0].Version){
						item.installType = 1
					}else{
						item.installType = 2
					}
				}
			})
			
			this.rankingData[index].list = res.list
			// console.log(this.rankingData)
		})
	},
	// 获取banner
	getBannerList(){
		bannerList(1).then(res=>{
			res.list.forEach(item=>{
				item.installType = 0
				let array = config.getSearch(this.installList,item.application.name)
				if(array.length > 0){
					if(item.application.version != array[0].Version){
						item.installType = 1
					}else{
						item.installType = 2
					}
				}
			})
			this.bannerData = res.list
		})
	},
	//  获取精品推荐模块
	getModuleList(){
		moduleList(1).then(res=>{
			// console.log(res)
			if(res.list.length > 0){
				this.classifyTab = res.list[0].children
				this.getModuleData(res.list[0].children[0].id,'softwareList')
				let newData = res.list.filter(item=>item.id != 10)
				let refList = ['rankingList','hotRecommend','utilityList','downloadList','editorRecommend']
				newData.forEach((item,index)=>{
					this.getModuleData(item.id,refList[index])
				})
			}
			
		})
	},
	  
	// 获取模块对应数据列表
	getModuleData(id,ref){
		moduleData(id).then(res=>{
			console.log('-----------------------')
			res.list.forEach(item=>{
				item.sel = false
				item.application.scoreVal = item.application.score / 100
				item.installType = 0
				let array = config.getSearch(this.installList,item.application.name)
				if(array.length > 0){
					if(item.application.version != array[0].Version){
						item.installType = 1
					}else{
						item.installType = 2
					}
				}
			})
			if(ref == 'rankingList'){
				this.rankingId = res.list[0].application.slug
			}
			this[ref] = res.list
		})
	},
	
	//  点击分类
	changeTab(index,data){
		if(this.classifyTabIndex!=index){
			this.classifyTabIndex=index
			this.getModuleData(data.id,'softwareList') 
		}
	},
	 mouseOverGame(ref,index,type){
		 this[ref][index].sel = type
	 },
	 updateOnlineStatus(e){
		 console.log(e)
	 },
	 changeBanner(index){
		 if(this.bannerIndex!=index){
			 this.bannerIndex=index
		 }
	 },
  },
  
}
</script>

<style lang="scss">
	.paid-25{
		padding: 0px 25px;
	}
	.billboard-sel{
		color: #00B386!important;
	}
	.software-list-btn{
		margin-top: 4px;
		width: 56px;
		height: 28px;
		text-align: center;
		line-height: 28px;
		background-color: #00B386;
		border-radius: 14px;
		color: #FFFFFF;
		font-size: 12px;
	}
	.omit-text{
		overflow: hidden; //超出的文本隐藏
		text-overflow: ellipsis; //溢出用省略号显示
		display: -webkit-box;
		-webkit-line-clamp: 1; // 超出多少行
		-webkit-box-orient: vertical;
	}
	.billboard-top{
		height: 40px;
		padding-left: 14px;
		padding-right: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.billboard-tel{
			color: #FFFFFF;
			font-size: 15px;
			font-weight: bold;
		}
		.billboard-text{
			color: #FFFFFF;
			font-size: 13px;
		}
	}
	.billboard-list{
		background-color: #F7F7F7;
		padding-left: 20px;
		padding-bottom: 25px;
		.billboard-item{
			display: flex;
			// align-items: center;
			padding-top: 20px;
			// margin-top: 20px;
			padding-bottom: 4px;
			height: 52px;
			.list-ranking{
				width: 24px;
				height: 26px;
				margin-top: 12px;
			}
			.list-shop-img{
				width: 49px;
				height: 49px;
				margin-left: 14px;
			}
			.billboard-item-info{
				margin-left: 10px;
				.billboard-shop-tel{
					color: #000000;
					font-size: 14px;
					word-wrap:break-word; 
					word-break:break-all;
					overflow: hidden; //超出的文本隐藏
					text-overflow: ellipsis; //溢出用省略号显示
					display: -webkit-box;
					-webkit-line-clamp: 1; // 超出多少行
					-webkit-box-orient: vertical;
				}
				.billboard-rate{
					margin-top: 4px;
				}
				.billboard-nums{
					color: #999999;
					font-size: 12px;
					margin-top: 2px;
				}
			}
		}
	}
	.download-bar{
		background-image: url(../assets/home/xaizb.png);
		background-size: 100% 100%;
	}
	.newp-bar{
		background-image: url(../assets/home/xinpb.png);
		background-size: 100% 100%;
	}
	.received-bar{
		background-image: url(../assets/home/haopb.png);
		background-size: 100% 100%;
	}
	.recommend-banner-img{
		width: 100%;
		height: 250px;
	}
	.practical-list{
		margin-top: 18px;
		padding-left: 25px;
		overflow-x: auto;
		white-space:nowrap;
		width: auto;
		overflow-y: hidden;
		.practical-item{
			width: 180px;
			margin-right: 14px;
			display: inline-block;
			.practical-item-img{
				width: 180px;
				height: 140px;
			}
			.practical-item-boom{
				background-color: #F7F7F7;
				height: 120px;
				padding: 0px 20px;
				.practical-boom-info{
					padding-top: 14px;
					display: flex;
					// align-items: center;
					.practical-boom-logo{
						width: 40px;
						height: 40px;
					}
					.practical-boom-ret{
						margin-left: 10px;
						padding-top: 2px;
						.practical-boom-tel{
							color: #000000;
							font-size: 14px;
							padding-bottom: 2px;
						}
					}
				}
				.practical-boom-num{
					color: #999999;
					font-size: 12px;
					margin-top: 8px;
				}
				.practical-boom-text{
					color: #666666;
					font-size: 12px;
					margin-top: 6px;
				}
			}
		}
	}
	.recommend-list{
		margin-top: 18px;
		padding-left: 25px;
		overflow-x: auto;
		white-space:nowrap;
		width: auto;
		overflow-y: hidden;
		.recommend-list-item:not(:last-child){
			margin-right: 14px;
		}
		.recommend-list-item{
			display: inline-block;
			width: 236px;
			.recommend-item-img{
				width: 236px;
				height: 140px;
			}
			.recommend-item-boom{
				background-color: #F7F7F7;
				height: 56px;
				display: flex;
				// align-items: center;
				padding-left: 16px;
				padding-top: 14px;
				.recommend-item-logo{
					width: 40px;
					height: 40px;
				}
				.recommend-item-info{
					margin-left: 14px;
					flex: 1;
					.recommend-item-name{
						color: #000000;
						font-size: 14px;
					}
					.recommend-item-text{
						color: #666666;
						font-size: 12px;
						margin-top: 4px;
					}
				}
			}
		}
	}
	.main-class-name{
		color: #000000;
		font-size: 20px;
		font-weight: bold;
		padding-top: 30px;
		padding-left: 25px;
	}
	.el-rate__icon{
		margin-right: 0px!important;
		font-size: 14px!important;
	}
	.ranking-card{
		margin-top: 20px;
		background: linear-gradient(180deg, #F4F6FC 0%, #FFFFFF 100%);
		border-radius: 4px;
		padding-left: 15px;
		padding-right: 10px;
		height: 250px;
		overflow: scroll;
		.ranking-card-top{
			display: flex;
			align-items: center;
			padding-top: 14px;
			padding-bottom: 11px;
			.ranking-line{
				width: 3px;
				height: 11px;
				border-radius: 2px;
				background-color: #00B386;
			}
			.ranking-title{
				color: #1E2644;
				font-size: 16px;
				font-weight: bold;
				margin-left: 6px;
			}
		}
		.ranking-card-item{
			display: flex;
			align-items: center;
			padding-bottom: 14px;
			.ranking-item-one{
				display: flex;
				align-items: center;
				// width: 100%;
				flex: 1;
				margin-left: 8px;
			}
			.ranking-rank{
				width: 15px;
				// padding: 1px 0px;
				text-align: center;
				border-radius: 2px;
				color: #FFFFFF;
				font-size: 12px;
				background-color: #FF7643;
			}
			.ranking-rank-0{
				background-color: #FF7643!important;
			}
			.ranking-rank-mo{
				background-color: #58CFFF!important;
			}
			.ranking-rank-1{
				background-color: #FFA101!important;
			}
			.ranking-rank-2{
				background-color: #FFC000!important;
			}
			.ranking-shop-img{
				width: 32px;
				height: 32px;
				
			}
			.ranking-rank-info{
				margin-left: 6px;
				
			}
			.ranking-rank-btn{
				margin-left: auto;
				width: 56px;
				height: 26px;
				text-align: center;
				line-height: 26px;
				color: #00B386;
				font-size: 12px;
				border: 1px solid rgba(0,179,134,0.5);
				border-radius: 14px;
			}
			.ranking-item-num{
				margin-left: auto;
				color: #999999;
				font-size: 12px;
			}
		}
	}
	.ranking-item-name{
		color: #4D5571;
		font-size: 13px;
	}
	.software-list{
		display: flex;
		margin-top: 25px;
		height: 50px;
		.software-list-img{
			width: 40px;
			height: 40px;
		}
		.software-list-info{
			margin-left: 8px;
			.software-list-tel{
				color: #333333;
				font-size: 13px;
			}
			.software-tel-sel{
				color: #00B386;
			}
			.software-list-text{
				color: #999999;
				font-size: 12px;
				margin-top: 7px;
				overflow: hidden; //超出的文本隐藏
				text-overflow: ellipsis; //溢出用省略号显示
				display: -webkit-box;
				-webkit-line-clamp: 1; // 超出多少行
				-webkit-box-orient: vertical;
			}
			
		}
	}
	.classify-tab-list{
		margin-top: 20px;
		background-color: #F4F6FC;
		border-radius: 4px;
		display: flex;
		align-items: center;
		.classify-tab-item{
			width: 16.66%;
			text-align: center;
			height: 32px;
			line-height: 32px;
			color: #4D5571;
			font-size: 12px;
		}
		.classify-sel{
			background-color: #00B386;
			border-radius: 4px;
			color: #FFFFFF;
		}
	}
	.banner-ret-item{
		height: 94px;
		position: relative;
		border-radius: 4px;
		.banner-ret-img{
			width: 100%;
			height: 94px;
		}
		.banner-ret-item-boom{
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba(0, 0, 0, 0.2);
			height: 26px;
			border-radius: 0px 0px 4px 4px;
			text-align: center;
			line-height: 26px;
			color: #FFFFFF;
			font-size: 14px;
		}
	}
	.home-banner-main{
		position: relative;
		height: 200px;
		border-radius: 4px;
		.home-banner-img{
			width: 100%;
			height: 200px;
		}
		.home-banner-list{
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			border-radius: 0px 4px 4px 0px;
			background-color: rgba(0, 0, 0, 0.5);
			padding: 0px 8px;
			.banner-list-item{
				margin-top: 8px;
				width: 88px;
				height: 40px;
				position: relative;
				// border: 2px solid transparent;
				.banner-list-item-img{
					width: 88px;
					height: 40px;
				}
				.banner-list-mode{
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: rgba(0, 0, 0, 0.5);
				}
			}
			.banner-sel{
				border: 2px solid #00B386;
			}
		}
		.banner-main-boom{
			position: absolute;
			bottom: 0;
			left: 0;
			right: 110px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
			height: 38px;
			padding-left: 16px;
			padding-right: 12px;
			.banner-boom-text{
				color: #FFFFFF;
				font-size: 14px;
			}
			.banner-boom-btn{
				width: 70px;
				height: 28px;
				text-align: center;
				line-height: 28px;
				background-color: #00B386;
				border-radius: 16px;
				color: #FFFFFF;
				font-size: 14px;
			}
		}
	}
	
	
	
	
	// .el-dialog__body{
	// 	padding: 0!important;
	// 	background-color: #242934!important;
	// 	border-radius: 6px!important;
	// }
	// .el-dialog__header{
	// 	padding: 0!important;
	// }
	// .el-dialog{
	// 	background: #242934!important;
	// 	border-radius: 6px!important;
	// }
	// .updateImg{
	// 	background-color: #000000;
	// 	z-index: 999999;
	// 	min-width: 0px!important;
	// 	border: 1px solid #20B7E3!important;
	// 	color: #FFFFFF!important;
	// 	padding: 8px 12px!important;
	// }
	// .noIcon .el-message__icon{
	// 	display:none!important;
	// }
	// .el-message--info .el-message__content{
	// 	color: #FFFFFF!important;
	// }
	// .el-message .el-icon-success{
	// 	color: #159A68!important;
	// 	font-size: 20px!important;
	// 	/* display: none; */
	// 	margin-right: 6px;
	// }
	
</style>
