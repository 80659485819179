<template>
  <div class="page-boxs">
      <div class="details-top">
		  <div class="soft-nav-left">
			  <img :src="softwareInfo.cover" class="soft-nav-img"/>
		  </div>
		  <div class="soft-info-item">
			  <div class="soft-info-nav">
				  <div class="soft-info-tel">{{softwareInfo.name}}</div>
				  <el-rate
				    v-model="softwareInfo.scoreVal"
				    disabled
				    text-color="#ff9900"
				    score-template="{value}" style="margin-left: 14px;">
				  </el-rate>
				  <div class="soft-rates">{{softwareInfo.scoreVal}}分</div>
			  </div>
			  <div class="soft-info-check">
				  <div class="soft-check-item" v-for="(value,index) in softwareInfo.applicationTips" :key="index">
					  <img src="../../assets/home/wudu-icons.png" class="soft-check-img"/>
					  <div class="soft-check-text">{{value.name}}</div>
				  </div>
			  </div>
			  <div class="soft-info-boom">
				  <div>最新版本：{{softwareInfo.version}}</div>
				  <div style="margin-left: 20px;">大小：{{softwareInfo.size}}</div>
				  <div style="margin-left: 20px;">更新时间：2022-08-17</div>
			  </div>
		  </div>
		  <div class="install-btn gestures" @click="install" v-if="btnStatus == 0">安装</div>
		  <div class="install-btn gestures" @click="install" v-if="btnStatus == 1">升级</div>
		  <div class="install-btn gestures" @click="opens" v-if="btnStatus == 2">打开</div>
	  </div>  
	  <div class="soft-banner gestures">
		<slider ref="slider" :options="options">
		    <slideritem v-for="(item,index) in someList" :key="index" :style="item.style" @tap="showSlider">
				<img :src="item.img" class="soft-banner-img"/>
			</slideritem>
		</slider>
		<div class="soft-banner-left"></div>
		<div class="soft-banner-ret"></div>
	  </div>
	  
	  <div class="soft-introduce-tel">软件介绍</div>
	  <div class="soft-introduce-text" v-html="softwareInfo.detail">
		  <!-- {{}} -->
	  </div>
	  <!-- <div class="soft-introduce-more">更多</div> -->
	  <div class="update-log-tel">更新日志</div>
	  <div class="update-log-list">{{softwareInfo.whatNew}}</div>
	  
	  <div class="recommend-item-nav">
		  <div class="recommend-item-tel">相关推荐</div>
		  <div class="recommend-item-ret" v-if="recommendList.length > 0">
			  <img src="../../assets/home/huanyihuan.png" class="recommend-ret-img"/>
			  <div class="recommend-ret-text gestures">换一换</div>
		  </div>
	  </div>
	  
	  <recommendList :list="recommendList"></recommendList>
	  <div class="empty-data" v-if="isLoad&&recommendList.length == 0">暂无数据!</div>
	  <div class="mask-items" v-show="sliderShow"></div>
	  <div class="slider-survey" v-show="sliderShow">
		  <div class="slider-survey-items">
			  <img src="../../assets/img/slider-left-icon.png" class="slider-survey-img gestures" @click="tapLeft" style="margin-right: 48px;"/>
			  <slider ref="sliders" :options="optionSurvey" style="width: 710px;margin: 0;">
			      <slideritem v-for="(item,index) in someList" :key="index">
			  		<img :src="item.img" class="slider-banner-img"/>
			  	</slideritem>
			  </slider>
			  <img src="../../assets/img/slider-ret-icon.png" class="slider-survey-img gestures" @click="tapRet" style="margin-left: 48px;"/>
			  <img src="../../assets/img/slider-close-icon.png" class="slider-close gestures" @click="sliderShow = false"/>
		  </div>
		  
	  </div>
  </div>
</template>

<script>
	import { slider, slideritem } from 'vue-concise-slider'
	import recommendList from "@/components/recommendList.vue"
	import {softwareDetails,recommendData} from "@/api/index.js"
	import { mapState } from 'vuex';
	import config from "@/utils/config.js"
	export default {
		components:{slider,slideritem,recommendList},
		data(){
			return {
				rankingVal:4,
				options: {
				   currentPage: 0,
				   tracking: false,
				   thresholdDistance: 100,
				   thresholdTime: 300,
				   infinite: 3,
				   slidesToScroll: 3,
				   loop: false
				},
				someList:[],
				softwareId:'',
				softwareInfo:{},
				recommendList:[],
				isLoad:false,
				installList:[],
				btnStatus:0,
				optionSurvey:{
					pagination: true,
					thresholdDistance: 100, // Sliding distance threshold
					thresholdTime: 300, // Sliding time threshold decision
					grabCursor: true, // Scratch style
					speed: 300 // Sliding speed
				},
				sliderShow:false
			}
		},

		activated() {
			this.softwareId = this.$route.query.id
			this.getDetails(this.softwareId)
		},
		created() {
			let lists = localStorage.getItem('installData')
			if(lists){
				this.installList = JSON.parse(lists)
			}
			
		},
		computed:{
			// ...mapState(['softwareIds']),
		},
		watch:{
			'$store.state.softwareIds':{
				handler(val) {
					if(val){
						this.getDetails(val)
					}
				}
			},
		},
		methods:{
			tapLeft(){
				this.$refs.sliders.$emit('slidePre')
			},
			tapRet(){
				this.$refs.sliders.$emit('slideNext')
			},
			showSlider(e){
				this.sliderShow = true
			},
			opens(){
				window.win_open_program(this.softwareInfo.name)
			},
			install(){
				// this.$store.commit('downloadInfo',this.softwareInfo)
				config.getDownload(this.softwareInfo)
				// window.win_install_program(this.softwareInfo.downloadUrl)
			},
			getDetails(id){
				this.someList = []
				this.getRecommend(id)
				softwareDetails(id).then(res=>{
					res.application.scoreVal = res.application.score / 100
					res.application.screenshots.forEach(item=>{
						this.someList.push({
							img:item,
							style:{
								'width': '32%',
								'margin-right': '2%'
							}
						})
					})
					this.softwareInfo = res.application
					console.log(this.installList)
					// let indexs = this.installList.findIndex(item=>item.DisplayName == res.application.name)
					let dataAr = config.getSearch(this.installList, res.application.name)
					if(dataAr.length > 0){
						if(res.application.version != dataAr[0].Version){
							this.btnStatus = 1
						}else{
							this.btnStatus = 2
						}
					}
				})
			},
			getRecommend(id){
				recommendData(id).then(res=>{
					res.list.forEach(item=>{
						item.installType = 0
						let array = config.getSearch(this.installList, item.name)
						if(array.length > 0){
							if(item.version != array[0].Version){
								item.installType = 1
							}else{
								item.installType = 2
							}
						}
					})
					this.recommendList = res.list
					this.isLoad = true
				})
			},
		}
	}
</script>
<style lang="scss">
	.mask-items{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 999;
		background: rgba(0, 0, 0, 0.8);
	}
	.slider-survey{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1000;
		display: flex;
		align-items: center;
		justify-content: center;
		.slider-survey-img{
			width: 28px;
			height: 50px;
		}
		.slider-banner-img{
			width: 710px;
			height: 420px;
			// margin: 0px 48px;
		}
		.slider-survey-items{
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
		}
		.slider-close{
			position: absolute;
			right: 26px;
			top: -50px;
			width: 30px;
			height: 30px;
		}
	}
	.empty-data{
		text-align: center;
		padding-top: 40px;
		color: #555555;
		font-size: 14px;
	}
	.recommend-item-nav{
		margin-top: 28px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.recommend-item-tel{
			color: #1E2644;
			font-size: 16px;
			font-weight: bold;
		}
		.recommend-item-ret{
			display: flex;
			align-items: center;
			.recommend-ret-img{
				width: 14px;
				height: 15px;
			}
			.recommend-ret-text{
				color: #00B386;
				font-size: 14px;
				margin-left: 4px;
			}
		}
	}
	.update-log-tel{
		margin-top: 30px;
		color: #1E2644;
		font-size: 16px;
		font-weight: bold;
	}
	.soft-introduce-tel{
		margin-top: 28px;
		color: #1E2644;
		font-size: 16px;
		font-weight: bold;
	}
	.soft-introduce-text{
		margin-top: 16px;
		color: #555555;
		font-size: 14px;
	}
	.soft-introduce-more{
		margin-top: 12px;
		color: #00B386;
		font-size: 14px;
	}
	.update-log-list{
		margin-top: 12px;
		color: #555555;
		font-size: 14px;
	}
	.swiper-container-horizontal > .slider-pagination-bullets{
		display: none!important;
	}
	.soft-banner{
		margin-top: 20px;
		position: relative;
		padding: 0px 22px;
		.soft-banner-left{
			position: absolute;
			left: 22px;
			top: 0;
			bottom: 0;
			background: linear-gradient(90deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
			width: 36px;
			z-index: 999;
		}
		.soft-banner-ret{
			position: absolute;
			right: 22px;
			top: 0;
			bottom: 0;
			background: linear-gradient(270deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
			width: 36px;
			z-index: 999;
		}
	}
	.soft-banner-img{
		width: 100%;
		height: 150px;
	}
	.el-rate__icon{
		margin-right: 0px!important;
		font-size: 14px!important;
	}
	.page-boxs{
		padding: 14px 20px 0px 30px;
	}
	.details-top{
		display: flex;
		align-items: center;
		padding-bottom: 24px;
		border-bottom: 1px solid #DFE6EE;
		.soft-nav-left{
			box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.16);
			background-color: #ffffff;
			padding: 16px;
			.soft-nav-img{
				width: 48px;
				height: 48px;
			}
		}
		.soft-info-item{
			margin-left: 24px;
			.soft-info-nav{
				display: flex;
				align-items: center;
			}
			.soft-info-tel{
				color: #4D5571;
				font-size: 16px;
				font-weight: bold;
			}
			.soft-rates{
				color: #4D5571;
				font-size: 12px;
				margin-left: 6px;
			}
			.soft-info-check{
				display: flex;
				align-items: center;
				margin-top: 10px;
				.soft-check-item{
					display: flex;
					align-items: center;
					margin-right: 24px;
					.soft-check-img{
						width: 14px;
						height: 16px;
					}
					.soft-check-text{
						color: #333333;
						font-size: 13px;
						margin-left: 6px;
					}
				}
			}
			.soft-info-boom{
				display: flex;
				align-items: center;
				margin-top: 10px;
				color: #646C88;
				font-size: 12px;
			}
		}
	}
	.install-btn{
		margin-left: auto;
		width: 130px;
		height: 42px;
		border-radius: 21px;
		text-align: center;
		line-height: 42px;
		background-color: #00B386;
		color: #FFFFFF;
		font-size: 16px;
	}
</style>
