<template>
  <div class="app-main-content">
      <div class="search-navs">
		  为你找到<span class="search-nav-sal">{{total}}</span>款<span class="search-nav-sal">{{keyword}}</span>相关软件
	  </div>
	  <div class="search-list-scroll"  v-if="dataList.length > 0">
			<div class="search-list-item" v-for="(value,index) in dataList" :key="index">
			<div class="search-list-img">
				<img :src="value.cover" class="search-list-img"/>
			</div>
			<div class="search-list-info">
				<div class="search-list-tel gestures" @click="details(value)">{{value.name}}</div>
				<div class="search-list-text">{{value.introduction}}</div>
			</div>
			<div class="search-list-size">135 MB</div>
			<div class="search-list-reot">
				<el-rate
					v-model="value.scoreVal"
					disabled
					text-color="#ff9900"
					score-template="{value}">
				</el-rate>
				<div class="search-list-num">{{value.scoreVal}}分</div>
			</div>
			
			<div class="search-list-btn gestures" @click="installs(value)" v-if="value.installType == 0">安装</div>
			<div class="search-list-btn gestures" @click="installs(value)" v-if="value.installType == 1">升级</div>
			<div class="search-list-btn gestures" @click="opensoftware(value)" v-if="value.installType == 2">打开</div>
		</div>
	  </div>
	  
	  <div class="empty-items" v-if="dataList.length == 0 && isLoad">
		  <img src="../../assets/home/ss-empty-imgs.png"/>
	  </div>
	  <div class="dissatisfied-item">没找到满意的结果？试试<span class="dissatisfied-text gestures">百度搜索“{{keyword}}”></span></div>
	  
	  <div class="recommend-item-nav">
	  	  <div class="recommend-item-tel">相关推荐</div>
	  	  <div class="recommend-item-ret gestures" v-if="recommendList.length > 0">
	  	  	 <img src="../../assets/home/huanyihuan.png" class="recommend-ret-img"/>
	  	  	 <div class="recommend-ret-text">换一换</div>
	  	  </div>
	  </div>
	  <recommendList :list="recommendList"></recommendList>
	  <div class="empty-data" v-if="isReLoad&&recommendList.length == 0">暂无数据!</div>
  </div>
</template>

<script>
	import recommendList from "@/components/recommendList.vue"
	import {searchFun,recommendData} from "@/api/index.js"
	import qs from 'qs'
	import config from "@/utils/config.js"
	export default {
		components:{recommendList},
		data(){
			return {
				rankingVal:4,
				keyword:'',
				dataList:[],
				total:'',
				isLoad:false,
				page:1,
				isComplete:false,
				recommendList:[],
				isReLoad:false,
				installList:[]
			}
		},
		watch:{
			'$store.state.keywordVal':{
				handler(val) {
					if(val){
						this.keyword = val
						this.page = 1
						this.isComplete = false
						this.dataList = []
						this.getDetails()
					}
				}
			},
		},
		activated() {
			this.keyword = this.$route.query.keyword
			this.page = 1
			this.isComplete = false
			this.dataList = []
			this.getDetails()
			// console.log('1233453546546')
		},
		created() {
			let lists = localStorage.getItem('installData')
			if(lists){
				this.installList = JSON.parse(lists)
			}
		},
		methods:{
			details(data){
				this.$router.push({ name: 'softwareDetails',query:{id:data.slug}});
			},
			installs(data){
				config.getDownload(data)
			},
			opensoftware(data){
				window.win_open_program(data.name)
			},
			scrollLoad(){
				// console.log('12334565436')
				if(!this.isComplete){
					this.page++
					this.getDetails()
				}
			},
			getDetails(){
				let prm = {
					keyword:this.keyword,
					page:this.page,
					page_size:100
				}
				searchFun(qs.stringify(prm)).then(res=>{
					this.isLoad = true
					res.list.forEach(item=>{
						item.scoreVal = item.score / 100
						item.installType = 0
						let array = config.getSearch(this.installList, item.name)
						if(array.length > 0){
							if(item.version != array[0].Version){
								item.installType = 1
							}else{
								item.installType = 2
							}
						}
					})
					this.dataList = this.dataList.concat(res.list)
					this.total = res.meta.pagination.total
					if(this.dataList.length == res.meta.pagination.total){
						this.isComplete = true
					}
					if(res.list.length > 0 && this.page == 1){
						this.getRecommend(res.list[0].slug)
					}else{
						this.getRecommend(56606)
					}
				})
			},
			getRecommend(id){
				recommendData(id).then(res=>{
					res.list.forEach(item=>{
						item.installType = 0
						let array = config.getSearch(this.installList, item.name)
						if(array.length > 0){
							if(item.version != array[0].Version){
								item.installType = 1
							}else{
								item.installType = 2
							}
						}
					})
					this.recommendList = res.list
					this.isReLoad = true
				})
			},
		}
	}
</script>
<style lang="scss">
	.empty-data{
		text-align: center;
		padding-top: 40px;
		color: #555555;
		font-size: 14px;
	}
	.search-list-scroll{
		// height: 380px;
	}
	.empty-items{
		text-align: center;
		padding-top: 20px;
		img{
			width: 176px;
			height: 176px;
		}
	}
	.recommend-item-nav{
		margin-top: 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.recommend-item-tel{
			color: #1E2644;
			font-size: 16px;
			font-weight: bold;
		}
		.recommend-item-ret{
			display: flex;
			align-items: center;
			.recommend-ret-img{
				width: 14px;
				height: 15px;
			}
			.recommend-ret-text{
				color: #00B386;
				font-size: 14px;
				margin-left: 4px;
			}
		}
	}
	.dissatisfied-item{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 30px;
		color: #646C88;
		font-size: 12px;
		.dissatisfied-text{
			color: #00B386;
		}
	}
	.el-rate__icon{
		margin-right: 0px!important;
		font-size: 14px!important;
	}
	.search-navs{
		padding: 7px 10px;
		background-color: #F2F6F6;
		display: flex;
		align-items: center;
		color: #4D5571;
		font-size: 12px;
		.search-nav-sal{
			color: #00B386;
			padding: 0px 4px;
			font-weight: bold;
		}
	}
	.search-list-item{
		display: flex;
		align-items: center;
		padding: 18px 10px 14px 0px;
		border-bottom: 1px solid #EEF2F6;
		.search-list-img{
			width: 48px;
			height: 48px;
		}
		.search-list-info{
			margin-left: 10px;
			width: 40%;
			.search-list-tel:hover{
				color: #00B386;
			}
			.search-list-tel{
				color: #1E2644;
				font-size: 13px;
				font-weight: bold;
			}
			.search-list-text{
				color: #646C88;
				font-size: 12px;
				margin-top: 8px;
				overflow: hidden; //超出的文本隐藏	
				text-overflow: ellipsis; //溢出用省略号显示
				display: -webkit-box;
				-webkit-line-clamp: 1; // 超出多少行
				-webkit-box-orient: vertical;
			}
		}
		.search-list-size{
			margin-left: 30px;
			color: #646C88;
			font-size: 12px;
		}
		.search-list-reot{
			display: flex;
			align-items: center;
			margin-left: 56px;
			.search-list-num{
				color: #4D5571;
				font-size: 12px;
			}
		}
		.search-list-btn{
			margin-left: auto;
			width: 90px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #00B386;
			border-radius: 16px;
			color: #00B386;
			font-size: 12px;
		}
	}
</style>
