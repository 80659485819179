<template>
  <div class="app-main-content">
      <div class="uninstall-nav-item">
		  <div class="table-column-one">软件名称</div>
		  <div class="table-column-two">软件大小</div>
		  <div class="table-column-two">安装时间</div>
		  <div class="table-column-three">使用频率</div>
		  <div class="table-column-labor">操作</div>
	  </div>
	  <div class="load-item" v-if="!isLoad">加载中,请稍后....</div>
	  <div v-if="isLoad">
		  <div class="uninstall-list" v-for="(value,index) in dataList" :key="index">
		  		  <div class="uninstall-list-one">
		  			  <img src="../../assets/img/no-select.png" class="uninstall-sel-img gestures" v-if="!value.sel" @click="changeSel(index,true)"/>
					  <img src="../../assets/img/yes-select.png" class="uninstall-sel-img gestures" v-if="value.sel" @click="changeSel(index,false)"/>
		  			  <div class="uninstall-shop-img" style="margin-left: 10px;">
		  				  <img :src="value.cover" class="uninstall-shop-img" v-if="value.cover"/>
						  <div class="cover-empty" v-if="!value.cover"></div>
		  			  </div>
		  			  <div class="stall-list-info">
		  				  <div class="stall-list-tel gestures">{{value.name}}</div>
		  				  <div class="stall-list-text">{{value.introduction || ''}}</div>
		  			  </div>
		  		  </div>
		  		  <div class="uninstall-list-two">{{value.size || ''}}</div>
		  		  <div class="uninstall-list-two"></div>
		  		  <div class="uninstall-list-three"></div>
		  		  <div class="uninstall-list-labor">
		  			  <div class="uninstall-btns gestures" @click="unloads">卸载</div>
		  		  </div>
		  </div>
	  </div>
	  
	  
	  <div class="uninstall-boom" v-if="isLoad">
		  <div class="uninstall-boom-text">已安装软件<span class="textss">{{dataList.length}}</span>款，系统盘(C)可用空间：<span class="textss">{{freeBytes}}</span></div>
		  
		  <div class="uninstall-boom-btn gestures" :class="{'uninstall-boom-sel':isSelect}" @click="allunloads">一键卸载</div>
	  </div>
  </div>
</template>

<script>
	import {searchFun,softwareDetails} from "@/api/index.js"
	import qs from 'qs'
	import config from "@/utils/config.js"
	export default {
		data(){
			return {
				dataList:[],
				freeBytes:'',
				installList:[],
				isLoad:false,
			}
		},
		created() {
			let data = window.win_program_list()
			this.installList = JSON.parse(data)
			localStorage.setItem('installData',data)
			this.handleData()
			this.freeBytes = config.getfilesize(window.win_free_bytes())
		},
		computed:{
			isSelect(){
				let result = false
				if(this.dataList.length > 0){
					let array = this.dataList.filter(item=>item.sel)
					if(array.length > 0){
						result = true
					}
				}
				return result
			},
		},
		methods:{
			handleData(){
				let item = this.installList.shift();
				let prm = {
					keyword:item.DisplayName,
					page:1,
					page_size:10
				}
				searchFun(qs.stringify(prm)).then(res=>{
					// console.log(res)
					if(res.list.length > 0){
						res.list[0].sel = false
						this.dataList.push(res.list[0])
						this.resultFun()
						// softwareDetails(res.list[0].slug).then(prm=>{
						// 	prm.application.sel = false
							
						// })
					}else{
						this.dataList.push({
							cover:'',
							name:item.DisplayName,
							introduction:'',
							size:'',
							sel:false
						})
						this.resultFun()
					}
					
				})
			},
			changeSel(index,type){
				this.dataList[index].sel = type
			},
			resultFun(){
				// console.log(this.installList.length)
				if(this.installList.length > 0){
					this.handleData()
				}else{
					this.isLoad = true
					// console.log(this.dataList)
				}
			},
			unloads(){
				window.win_uninstall_program()
			},
			allunloads(){
				let selList = this.dataList.filter(item=>item.sel)
				if(selList.length == 0){
					return false
				}
				window.win_uninstall_program()
			},
		}
	}
</script>
<style lang="scss">
	.load-item{
		text-align: center;
		padding-top: 20px;
		color: #999999;
		font-size: 15px;
	}
	.uninstall-boom{
		position: fixed;
		bottom: 0;
		left: 170px;
		right: 0;
		display: flex;
		height: 70px;
		align-items: center;
		padding: 0px 30px;
		background-color: #ffffff;
		z-index: 999;
		.uninstall-boom-text{
			color: #4D5571;
			font-size: 14px;
			.textss{
				color: #00B386;
			}
		}
		.uninstall-boom-btn{
			margin-left: auto;
			width: 120px;
			height: 36px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 18px;
			border: 1px solid #E0E0E0;
			color: #999999;
			font-size: 12px;
		}
		.uninstall-boom-sel{
			color: #333333!important;
		}
	}
	.uninstall-nav-item{
		display: flex;
		background-color: #F2F6F6;
		padding: 6px 0px;
		color: #999999;
		font-size: 12px;
		.table-column-one{
			text-align: center;
			width: 35%;
		}
		.table-column-two{
			width: 15%;
		}
		.table-column-three{
			width: 18%;
		}
		.table-column-labor{
			width: 17%;
			text-align: center;
		}
	}
	.uninstall-list{
		display: flex;
		align-items: center;
		padding-top: 18px;
		padding-bottom: 14px;
		border-bottom: 1px solid #EEF2F6;
		.uninstall-list-one{
			display: flex;
			align-items: center;
			width: 35%;
			.uninstall-sel-img{
				width: 15px;
				height: 15px;
			}
			.uninstall-shop-img{
				width: 48px;
				height: 48px;
			}
			.cover-empty{
				width: 48px;
				height: 48px;
				border-radius: 4px;
				background-color: #F2F6F6;
			}
			.stall-list-info{
				margin-left: 10px;
				.stall-list-tel:hover{
					color: #00B386;
				}
				.stall-list-tel{
					color: #1E2644;
					font-size: 13px;
					font-weight: bold
				}
				.stall-list-text{
					margin-top: 8px;
					color: #646C88;
					font-size: 12px;
					overflow: hidden; //超出的文本隐藏
					text-overflow: ellipsis; //溢出用省略号显示
					display: -webkit-box;
					-webkit-line-clamp: 1; // 超出多少行
					-webkit-box-orient: vertical;
				}
			}
		}
		.uninstall-list-two{
			width: 15%;
			color: #646C88;
			font-size: 12px;
		}
		.uninstall-list-three{
			width: 18%;
			color: #646C88;
			font-size: 12px;
		}
		.uninstall-list-labor{
			width: 17%;
			display: flex;
			align-items: center;
			justify-content: center;
			.uninstall-btns{
				width: 90px;
				height: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid #D8D8D8;
				border-radius: 16px;
				color: #333333;
				font-size: 12px;
			}
			.uninstall-btns:hover{
				background-color: #FF6631;
				border: 1px solid #FF6631;
				color: #ffffff;
			}
		}
	}
</style>
