<template>
	<el-row :gutter="30" style="margin-top: 20px;padding: 0px 20px;">
		<el-col :span="12" v-for="(value,index) in list" :key="index">
			<div class="software-list-item">
				<div class="software-item-img" @click="changeData(value.application)">
					<img :src="value.application.cover" class="software-item-img"/>
				</div>
				<div class="software-item-info">
					<div class="software-item-tel">
						<div class="gestures software-tels" @click="changeData(value.application)">{{value.application.name}}</div>
						<el-rate
						  v-model="value.scoreVal"
						  disabled
						  text-color="#ff9900"
						  void-color="#E7EAF0"
						  score-template="{value}" v-if="isScore">
						  
						</el-rate>
					</div>
					<div class="software-item-text ellipsis-text">{{value.application.introduction}}</div>
				</div>
				<div class="software-item-btn gestures" @click="installs(value)" v-if="value.installType == 0">安装</div>
				<div class="software-item-btn gestures" @click="installs(value)" v-if="value.installType == 1">升级</div>
				<div class="software-item-btn gestures" @click="openAr(value)" v-if="value.installType == 2">打开</div>
			</div>
		</el-col>
	</el-row>
</template>

<script>
	import config from "@/utils/config.js"
	export default {
		props:{
			isScore: {
				type: Boolean,
				default: false,
			},
			list:{
				type:Array
			}
		},
		data(){
			return{
				rankingVal:4
			}
		},
		methods:{
			changeData(data){
				this.$router.push({ name: 'softwareDetails',query:{id:data.slug}});
			},
			installs(data){
				config.getDownload(data.application)
				// this.$store.commit('downloadInfo',data)
			},
			openAr(data){
				window.win_open_program(data.name)
			},
		}
	}
</script>

<style lang="scss">
	.software-list-item{
		display: flex;
		align-items: center;
		padding: 16px 0px;
		border-bottom: 1px solid #EEF2F6;
		.software-item-img{
			width: 48px;
			height: 48px;
		}
		.software-item-info{
			margin-left: 10px;
			width: 59%;
			.software-item-tel{
				color: #1E2644;
				font-size: 13px;
				font-weight: bold;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			.software-item-text{
				color: #646C88;
				font-size: 12px;
				margin-top: 8px;
			}
		}
		.software-item-btn{
			margin-left: auto;
			width: 70px;
			height: 30px;
			border-radius: 20px;
			border: 1px solid #00B386;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #00B386;
			font-size: 12px;
		}
		
	}
	.software-item-btn:hover{
		background-color: #00B386!important;
		color: #ffffff!important;
	}
	.el-rate__icon{
		margin-right: 0px!important;
		font-size: 14px!important;
	}
	.software-tels:hover{
		color: #00B386;
	}
	.software-tels{
		overflow: hidden; //超出的文本隐藏
		text-overflow: ellipsis; //溢出用省略号显示
		display: -webkit-box;
		-webkit-line-clamp: 1; // 超出多少行
		-webkit-box-orient: vertical;
	}
</style>