<template>
  <div class="app-main-content">
      <el-row>
        <el-col :span="3" v-for="(value,index) in necrTab" :key="index" style="margin-bottom: 18px;">
			<div class="classify-title gestures" :class="{'classTas':necrTabIndex==index}" @click="changeTel(index,value)">{{value.name}}</div>
		</el-col>
      </el-row>
	  
	  <div class="all-software">
		  <div class="all-software-tel">全部软件</div>
		  <div class="default-rank gestures">
			  <span  @click="changeDefa">默认排行</span>
			  <img src="../../assets/img/ruan-domr-icon.png" class="default-rank-icon" @click="changeDefa"/>
			  <!-- <img  src=""/> -->
			  <div class="default-items" v-if="showDefault">
				  <div class="default-item-list gestures" :class="{'default-sel':orderby==value.type}" v-for="(value,index) in defaultList" :key="index" @click="changeDefault(value)">
					{{value.text}}
					<!-- <div class="defa-status">√</div> -->
					<img src="../../assets/img/ruan-dui-icon.png" class="defa-status" v-show="value.type == orderby"/>
				  </div>
			  </div>
		  </div>
	  </div>
	  <!-- <softwareList :isScore="true"></softwareList> -->
	  <div v-infinite-scroll="load" style="overflow:auto;height: 536px;" infinite-scroll-immediate="false">
	  	<softwareList :list="dataList" :isScore="true"></softwareList>
	  	<div class="load-item" v-if="loading">
	  		<i class="el-icon-loading"></i>
	  		<span class="load-item-text">加载中...</span>
	  	</div>
	  </div>
  </div>
</template>

<script>
	import softwareList from "@/components/softwareList.vue"
	import {categoryList,categoryData} from "@/api/index.js"
	import config from "@/utils/config.js"
	export default {
		components:{softwareList},
		data(){
			return {
				necrTabIndex:0,
				defaultList:[
					{text:'默认排行',type:'id'},
					{text:'下载量排行',type:'download_count'},
					{text:'评分排行',type:'score'}
				],
				defaultIndex:0,
				showDefault:false,
				necrTab:[],
				page:1,
				size:20,
				loading: false,
				installList:[],
				orderby:'id'
			}
		},
		computed: {
			dataList () {
				let result = []
				if(this.necrTab.length > 0){
					if(this.necrTab[this.necrTabIndex].list.length > 0){
						result = this.necrTab[this.necrTabIndex].list
					}
				}
				return result
			},
		},
		created() {
			this.getClassList()
			let lists = localStorage.getItem('installData')
			if(lists){
				this.installList = JSON.parse(lists)
			}
		},
		methods:{
			changeDefault(data){
				if(data.type!=this.orderby){
					this.orderby = data.type
					this.necrTab[this.necrTabIndex].page = 1
					this.necrTab[this.necrTabIndex].list = []
					this.gerCategoryData(this.necrTab[this.necrTabIndex].id)
				}
			},
			load(){
				if(!this.necrTab[this.necrTabIndex].isComplete){
					this.loading = true
					this.necrTab[this.necrTabIndex].page++
					this.gerCategoryData(this.necrTab[this.necrTabIndex].id)
				}
			},
			getClassList(){
				categoryList(0).then(res=>{
					// console.log(res)
					let list = res.list
					list.forEach(item=>{
						item.sel = false,
						item.list = [],
						item.isLoad = false,
						item.page = 1,
						item.isComplete = false,
						item.total = 0
					})
					this.necrTab = list
					this.gerCategoryData(list[0].id)
				})
			},
			gerCategoryData(id){
				categoryData(id,this.page,this.size,'',this.orderby).then(res=>{
					res.list.forEach(item=>{
						item.application = {},
						item.application.name = item.name,
						item.application.cover = item.cover,
						item.application.introduction = item.introduction,
						item.application.slug = item.slug,
						item.application.id = item.id
						item.scoreVal = item.score / 100
						item.installType = 0
						let array = config.getSearch(this.installList, item.name)
						if(array.length > 0){
							if(item.version != array[0].Version){
								item.installType = 1
							}else{
								item.installType = 2
							}
						}
					})
					this.necrTab[this.necrTabIndex].list = this.necrTab[this.necrTabIndex].list.concat(res.list)
					this.necrTab[this.necrTabIndex].isLoad = true
					this.necrTab[this.necrTabIndex].total = res.meta.pagination.total
					if(this.necrTab[this.necrTabIndex].list.length == res.meta.pagination.total){
						this.necrTab[this.necrTabIndex].isComplete = true
					}
					this.loading = false
				})
			},
			changeDefa(){
				this.showDefault = !this.showDefault
			},
			changeTel(index,data){
				if(this.necrTabIndex!=index){
					this.necrTabIndex=index
					if(!data.isLoad){
						this.gerCategoryData(data.id)
					}
				}
			},
		}
	}
</script>
<style lang="scss">
	.load-item{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 10px;
		.load-item-text{
			font-size: 15px;
			margin-left: 5px;
		}
	}
	.classify-title{
		color: #1E2644;
		font-size: 13px;
	}
	.classTas{
		color: #00B386;
		font-size: 13px;
		font-weight: bold;
	}
	.all-software{
		display: flex;
		align-items: center;
		margin-top: 10px;
		.all-software-tel{
			color: #1E2644;
			font-size: 18px;
			font-weight: bold;
		}
	}
	.default-rank{
		margin-left: auto;
		display: flex;
		align-items: center;
		position: relative;
		color: #4D5571;
		font-size: 12px;
		.default-rank-icon{
			width: 18px;
			height: 12px;
			margin-left: 4px;
		}
		.default-items{
			position: absolute;
			right: 0;
			top: 22px;
			width: 130px;
			background-color: #FFFFFF;
			box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.16);
			border-radius: 4px;
			padding: 5px 0px;
			z-index: 999;
		}
		.default-sel{
			background-color: #EEF0F6;
		}
		.default-item-list{
			position: relative;
			height: 32px;
			line-height: 32px;
			padding-left: 42px;
			color: #000000;
			font-size: 12px;
			.defa-status{
				position: absolute;
				left: 15px;
				top: 11px;
				width: 12px;
				height: 9px;
			}
		}
	}
</style>
