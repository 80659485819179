<template>
  <div class="app-main-content">
	  <div class="necr-tab-main">
		<div class="necr-tab-list">
			<div class="necr-tab-item gestures" :class="{'necr-tabs':necrTabIndex==index,'neceClick':value.sel}" @click="changeNece(index,value)"
			 v-for="(value,index) in necrTab" :key="index" @mouseover="neceOverGame(index,false,value)"  @mouseleave="neceOverGame(index,true,value)">
				<span>{{value.name}}</span>
				
			 </div>
		</div>
		
		<div class="default-lists" @click="changeDefa">
			<span class="default-lists-tel">默认排行</span>
			<img src="../../assets/img/ruan-domr-icon.png" class="necr-tab-img"/>
		</div>
		
		<div class="default-items" v-if="showDefault">
			<div class="default-item-list gestures" :class="{'default-sel':orderby==value.type}" v-for="(value,index) in defaultList" :key="index" @click="changeDefault(value)">
				 {{value.text}}
				 <!-- <div class="defa-status">√</div> -->
				 <img src="../../assets/img/ruan-dui-icon.png" class="defa-status" v-show="value.type == orderby"/>
			</div>
		</div>
		
	  </div>
        <div v-infinite-scroll="load" style="overflow:auto;height: 536px;" infinite-scroll-immediate="false">
			<softwareList :list="dataList" :isScore="true"></softwareList>
			<div class="load-item" v-if="loading">
				<i class="el-icon-loading"></i>
				<span class="load-item-text">加载中...</span>
			</div>
		</div>
		
		
  </div>
</template>

<script>
	import softwareList from "@/components/softwareList.vue"
	import {categoryList,categoryData} from "@/api/index.js"
	import config from "@/utils/config.js"
	export default {
		components:{softwareList},
		data(){
			return {
				necrTab:[],
				necrTabIndex:0,
				showDefault:false,
				defaultList:[
					{text:'默认排行',type:'id'},
					{text:'下载量排行',type:'download_count'},
					{text:'评分排行',type:'score'}
				],
				defaultIndex:0,
				page:1,
				size:20,
				loading: false,
				installList:[],
				orderby:'id'
			}
		},

		created() {
			this.getCategory()
			let lists = localStorage.getItem('installData')
			if(lists){
				this.installList = JSON.parse(lists)
			}
		},
		computed: {
			dataList () {
				let result = []
				if(this.necrTab.length > 0){
					if(this.necrTab[this.necrTabIndex].list.length > 0){
						result = this.necrTab[this.necrTabIndex].list
					}
				}
				return result
			},
		},
		methods:{
			changeDefault(data){
				if(data.type!=this.orderby){
					this.orderby = data.type
					this.necrTab[this.necrTabIndex].page = 1
					this.necrTab[this.necrTabIndex].list = []
					this.gerCategoryData(this.necrTab[this.necrTabIndex].id)
				}
			},
			load(){
				if(!this.necrTab[this.necrTabIndex].isComplete){
					this.loading = true
					this.necrTab[this.necrTabIndex].page++
					this.gerCategoryData(this.necrTab[this.necrTabIndex].id)
				}
			},
			getCategory(){
				categoryList(0).then(res=>{
					let index = res.list.findIndex(item=>item.id == 12)
					let list = res.list[index].tags
					list.forEach(item=>{
						item.sel = false,
						item.list = [],
						item.isLoad = false,
						item.page = 1,
						item.isComplete = false,
						item.total = 0
					})
					
					this.necrTab = list
					this.gerCategoryData(list[0].id)
				})
			},
			gerCategoryData(tagId){
				categoryData(12,this.necrTab[this.necrTabIndex].page,this.size,tagId,this.orderby).then(res=>{
					res.list.forEach(item=>{
						item.application = {},
						item.application.name = item.name,
						item.application.cover = item.cover,
						item.application.introduction = item.introduction,
						item.application.slug = item.slug,
						item.application.id = item.id
						item.scoreVal = item.score / 100
						item.installType = 0
						let array = config.getSearch(this.installList, item.name)
						if(array.length > 0){
							if(item.version != array[0].Version){
								item.installType = 1
							}else{
								item.installType = 2
							}
						}
					})
					this.necrTab[this.necrTabIndex].list = this.necrTab[this.necrTabIndex].list.concat(res.list)
					this.necrTab[this.necrTabIndex].isLoad = true
					this.necrTab[this.necrTabIndex].total = res.meta.pagination.total
					if(this.necrTab[this.necrTabIndex].list.length == res.meta.pagination.total){
						this.necrTab[this.necrTabIndex].isComplete = true
					}
					this.loading = false
					
				})
			},
			neceOverGame(index,type,data){
				if(!type&&this.necrTabIndex!=index){
					this.necrTab[index].sel = true
				}else{
					this.necrTab[index].sel = false
				}
			},
			changeNece(index,data){
				if(this.necrTabIndex!=index){
					this.necrTab[index].sel = false
					this.necrTabIndex=index
					if(!data.isLoad){
						this.gerCategoryData(data.id)
					}
					
				}
			},
			changeDefa(){
				this.showDefault = !this.showDefault
			},
		}
	}
</script>
<style lang="scss">
	.load-item{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 10px;
		.load-item-text{
			font-size: 15px;
			margin-left: 5px;
		}
	}
	.noMore-item{
		text-align: center;
		font-size: 15px;
		margin-top: 10px;
	}
	.default-lists{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 6px;
		.default-lists-tel{
			color: #000000;
			font-size: 14px;
		}
	}
	.necr-tab-main{
		background-color: #F2F6F6;
		border-radius: 6px;
		display: flex;
		align-items: center;
		position: relative;
		justify-content: space-between;
	}
	.necr-tab-list{
		display: flex;
		
		border-radius: 6px;
		padding: 9px 3px;
		.necr-tab-item{
			width: 100px;
			height: 32px;
			border-radius: 3px;
			text-align: center;
			line-height: 32px;
			color: #000000;
			font-size: 14px;
			margin-left: 6px;
			
			// display: flex;
			// align-items: center;
			// justify-content: center;
		}
		.necr-tabs{
			background-color: #00B386;
			color: #ffffff;
		}
		.neceClick{
			background-color: #DFEBE6;
		}
	}
	.ranking-item{
		color: #4D5571!important;
		font-size: 12px!important;
	}
	.necr-tab-img{
		width: 18px;
		height: 12px;
		margin-left: 4px;
	}
	.default-items{
		position: absolute;
		right: 0;
		top: 38px;
		width: 130px;
		background-color: #FFFFFF;
		box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.16);
		border-radius: 4px;
		padding: 5px 0px;
		z-index: 999;
	}
	.default-sel{
		background-color: #EEF0F6;
	}
	.default-item-list{
		position: relative;
		height: 32px;
		line-height: 32px;
		padding-left: 42px;
		color: #000000;
		font-size: 12px;
		text-align: left;
		.defa-status{
			position: absolute;
			left: 15px;
			top: 11px;
			width: 12px;
			height: 9px;
		}
	}
</style>
