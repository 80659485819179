<template>
	<div>
		<el-row :gutter="13" style="margin-top: 7px;">
			<el-col :span="8" v-for="(value,index) in list" :key="index">
				 <div class="everybody-item gestures" @mouseover="mouseOverGrid(index,true)"  @mouseleave="mouseOverGrid(index,false)">
					 <div class="everybody-item-img" @click="goDetails(value.application)">
						 <img :src="value.application.cover" class="everybody-item-img"/>
					 </div>
					 <div class="everybody-item-ret">
						 <div class="everybody-item-name" :class="{'everybody-sel':value.sel}" @click="goDetails(value.application)">{{value.application.name}}</div>
						 <div class="everybody-item-info" v-show="!value.sel">
							 <el-rate
							   v-model="value.application.scoreVal"
							   disabled
							   text-color="#ff9900"
							   score-template="{value}">
							 </el-rate>
							 <div class="everybody-item-num">{{value.application.downloadCount}}</div>
						 </div>
						 <div class="everybody-item-texts omit-text" v-show="!value.sel">{{value.application.introduction}}</div>
						 <div class="software-list-btn" v-show="value.sel&&value.installType == 0" @click="installs(value)">安装</div>
						 <div class="software-list-btn" v-show="value.sel&&value.installType == 1" @click="installs(value)">升级</div>
						 <div class="software-list-btn" v-show="value.sel&&value.installType == 2" @click="openar(value)">打开</div>
					 </div>
				 </div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import config from "@/utils/config.js"
	export default {
		props: {
			list:{
				type:Array
			}
		},
		data(){
			return {
				rankingVal:4
			}
		},
		methods:{
			mouseOverGrid(index,type){
				this.list[index].sel = type
			},
			goDetails(data){
				this.$router.push({ name: 'softwareDetails',query:{id:data.slug}});
			},
			installs(data){
				config.getDownload(data.application)
				// this.$store.commit('downloadInfo',data.application)
			},
			openar(data){
				window.win_open_program(data.application.name)
			},
		}
	}
</script>

<style lang="scss">
	.software-list-btn{
		margin-top: 4px;
		width: 56px;
		height: 28px;
		text-align: center;
		line-height: 28px;
		background-color: #00B386;
		border-radius: 14px;
		color: #FFFFFF;
		font-size: 12px;
	}
	.everybody-item{
		background-color: #F7F7F7;
		border-radius: 6px;
		display: flex;
		padding-left: 15px;
		height: 88px;
		padding-top: 14px;
		margin-top: 11px;
		.everybody-item-img{
			width: 40px;
			height: 40px;
		}
		.everybody-item-ret{
			margin-left: 12px;
			.everybody-item-name{
				color: #000000;
				font-size: 15px;
			}
			.everybody-sel{
				color: #00B386;
			}
			.everybody-item-info{
				display: flex;
				align-items: center;
				margin-top: 8px;
				.everybody-item-num {
					margin-left: 8px;
					color: #999999;
					font-size: 12px;
				}
			}
			.everybody-item-texts{
				margin-top: 10px;
				color: #666666;
				font-size: 12px;
			}
		}
	}
</style>